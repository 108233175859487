// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

// ===========================|| LIGHT BOX ||=========================== //
.slick-arrow:before {
    color: $grey500;
}

// ===========================|| LIGHT BOX ||=========================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ===========================|| APEXCHART ||=========================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ===========================|| PERFECT SCROLLBAR ||=========================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ===========================|| ANIMATION KEYFRAMES ||=========================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}
.css-12t14fp-MuiDataGrid-root div.MuiDataGrid-cell {
    margin: 40px 0px;
    background: #fff;
}
@import './customized.scss';

.dialog-enter {
    opacity: 0.01;
    transform: scale(1.1);
}

.dialog-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 300ms;
}

.dialog-exit {
    opacity: 1;
    transform: scale(1);
}

.dialog-exit-active {
    opacity: 0.01;
    transform: scale(1.1);
    transition: all 300ms;
}

input#combo-box-demo::placeholder,
input#combo-box-demo {
    font-size: 24px;
}

.css-1ajk5nq-MuiDataGrid-root .MuiDataGrid-cell {
    border-color: #e5e5e5;
}
